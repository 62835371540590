/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import FileInput from '../../../../fileInput'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'

/**
 *  dataTypeId 25
 *
 * */

const supportedFiles = ['image/jpeg', 'image/png', 'image/tiff', 'image/ief']

const CameraImage = ({
  fieldList,
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  onError,
  isFileUpload,
  onDelete,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const onChange = file => {
    const { url, isValid, msg } = file
    if (!isValid) {
      onValidate(false)
      onError(msg)
    }
    if (!!isRequired) {
      if (!!url) {
        onValidate(true)
      } else {
        onValidate(false)
        onError(msg)
      }
    } else {
      onValidate(true)
    }
    if (isValid) {
      onError('')
      dispatch({
        type: smartFormConst.SET_FIELD_VALUE,
        fieldId: field.field_id,
        formId: field.form_id,
        value: loadFinalValue(field, url),
      })
    }
  }

  useEffect(() => {
    if (!!fields[field.field_id]) {
      if (fields[field.field_id].field_value !== '') {
        onValidate(true)
      }
    }
  }, [fields[field.field_id]])

  return (
    <FileInput
      field={field}
      onChange={onChange}
      fieldList={fieldList}
      isDisabled={isDisabled}
      placeholder=".jpg, .jpeg, .png"
      supportedFiles={supportedFiles}
      onError={onError}
      value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
      isFileUpload={isFileUpload}
      onDelete={onDelete}
    />
  )
}

export default CameraImage
