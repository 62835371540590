/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Input } from '@chakra-ui/react';
import { useSmartFormContext } from '../../../../../context/smart-form.context';
import { smartFormConst } from '../../../../../utils/action.constant';
import { loadFinalValue } from '../../../../../utils/form.util';
import { toSnakeCase } from '../../../../../utils/common.util';
/**
 * dataTypeID 22
 * dataTypeName  Email Input
 *
 */
const EmailInput = ({
  field,
  isRequired,
  onValidate,
  isDisabled = true,
  onError,
}) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext();
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {};

  const onChange = e => {
    let value = e;
    const createRegExp = (str, opts) =>
      new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '');
    const regEx = createRegExp`
      ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
      (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
      (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`;
    if (!!isRequired) {
      if (!!value) {
        if (!!regEx.test(value)) {
          onError(null);
          onValidate(true);
        } else {
          console.log('invalid email!');
          onError('Invalid email');
          onValidate(false);
        }
      } else {
        onValidate(false);
      }
    } else {
      if (!!regEx.test(value)) {
        onError(null);
        onValidate(true);
      } else {
        onError('Invalid email');
        onValidate(false);
      }
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, value),
    });
  };

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange(fields[field.field_id].field_value);
    }
  }, [!!fields[field.field_id]]);

  return (
    <Input
      className={`email-id-field-${toSnakeCase(field.field_name)}`}
      autoComplete="off"
      type="email"
      isDisabled={isDisabled}
      placeholder={field.field_place_holder||`Enter ${field.field_name}`}
      borderRadius="md"
      size="sm"
      value={fields[field.field_id] ? fields[field.field_id].field_value : ''}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export default EmailInput;
