/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { HStack, Text } from '@chakra-ui/react'
import { toSnakeCase } from '../../../../../utils/common.util'

const LabelField = ({ onSkip, field: { field_name } }) => {
	useEffect(() => {
		if (!!onSkip) {
			onSkip()
		}
	}, [])
	return (
		<HStack w='full' p={2} my={2} bg='secondary'>
			<Text
				className={`label-field-${toSnakeCase(field_name)}`}
				fontSize='lg'
				w={'100%'}
			>
				{field_name}
			</Text>
		</HStack>
	)
}

export default LabelField
