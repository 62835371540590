/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import { Input } from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'

const Location = ({ field, isDisabled = true, isRequired, onValidate }) => {
	const [userCoords, setUserCoords] = useState(null)
	const [userCoordsexist, setUserCoordsexist] = useState(false)
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()

	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	useEffect(() => {
		if (!!fields[field.field_id] && !!fields[field.field_id].field_value) {
			let fieldValue = fields[field.field_id].field_value
			try {
				// Check if fieldValue is in JSON format
				let parsedValue
				try {
					parsedValue = JSON.parse(fieldValue)
				} catch (jsonError) {
					// If parsing as JSON fails, assume it's a comma-separated string
					let latLngArray = fieldValue.split(',')
					setUserCoordsexist(true)
					setUserCoords({
						latitude: latLngArray[0].trim() || '',
						longitude: latLngArray[1].trim() || '',
					})
					onValidate(true)
					let formattedDate = `${latLngArray[0].trim() || ''}, ${
						latLngArray[1].trim() || ''
					}`
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: field.field_id,
						formId: field.form_id,
						value: loadFinalValue(field, formattedDate),
					})
					return // Exit the try block since we've handled the string case
				}

				// If fieldValue is in JSON format, check if it contains location_data
				if (parsedValue && parsedValue.location_data) {
					setUserCoords({
						latitude: parsedValue.location_data.location_latitude || '',
						longitude: parsedValue.location_data.location_longitude || '',
					})
					onValidate(true)
					let formattedDate = `${
						parsedValue.location_data.location_latitude || ''
					}, ${parsedValue.location_data.location_longitude || ''}`
					dispatch({
						type: smartFormConst.SET_FIELD_VALUE,
						fieldId: field.field_id,
						formId: field.form_id,
						value: loadFinalValue(field, formattedDate),
					})
				}
			} catch (err) {
				// Handle any errors
			}
		} else {
			if (Object.keys(fields).length) getLocation()
		}
	}, [fields?.[field?.field_id]?.field_value])

	const getLocation = useCallback(() => {
		if (navigator.geolocation && !fields[field.field_id]) {
			navigator.geolocation.getCurrentPosition(position => {
				setUserCoords(position.coords)
				onValidate(true)
				let formattedDate =
					position.coords.latitude + ', ' + position.coords.longitude
				dispatch({
					type: smartFormConst.SET_FIELD_VALUE,
					fieldId: field.field_id,
					formId: field.form_id,
					value: loadFinalValue(field, formattedDate),
				})
			})
		}
	}, [fields])
	return (
		<>
			<Input
				type='text'
				// required={isRequired}
				//autoFocus={true}
				isDisabled={isDisabled}
				placeholder={`Curent Location`}
				borderRadius='md'
				size='sm'
				value={
					userCoords !== null
						? userCoords.latitude + ', ' + userCoords.longitude
						: 'NA'
				}
			/>
		</>
	)
}

export default Location
