/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
	Input,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	IconButton,
	Flex,
	Box,
	Text,
	useToast,
	Button,
} from '@chakra-ui/react'
import { MdDelete } from 'react-icons/md'
import Select from '../../../../chakra-react-select'
import '../../Reference/ProductCart/style.scss'
import { useLoadLeaveMapping } from '../../../../../hooks/asset.hooks'
import { smartFormConst } from '../../../../../utils/action.constant'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import { loadFinalValue } from '../../../../../utils/form.util'
import moment from 'moment'
import { sectionDetailedTrack } from '../../../../../containers/repository/repo.utils'
import { GA_CATEGORY_LEAVE } from './../../../../../containers/repository/repo.utils'

let initialData = {
	leave_type_id: 0,
	leave_type_name: '',
	leave_type_category_id: 0,
	leave_type_category_name: 'Paid',
	Leave_available: 0,
	leave_applied: '',
	leave_start_datetime: '',
	leave_end_datetime: '',
}

const Leave = ({
	field,
	isRequired,
	isDisabled = true,
	onValidate,
	isBulkEdit,
	fieldIndex,
	onError,
	...rest
}) => {
	const [leaveTypeOptions, setleaveTypeOptions] = useState([])
	const [leaveType, setleaveType] = useState({})
	const [leaveData, setleaveData] = useState([])
	const [leaveForm, setleaveForm] = useState(initialData)
	const [dummy, setdummy] = useState(true)
	const [ishalfDay, setIshalfDay] = useState(false)
	const [times, setTimes] = useState({ start: '', end: '' })
	const { mutate: loadLeaveMappings } = useLoadLeaveMapping()
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const toast = useToast({
		duration: 2000,
	})
	const {
		state: { authData },
	} = useAuthContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	// console.log(fields, 'fields');
	const { field_id } = field

	useEffect(() => {
		loadLeaveMappings(
			{
				leave_type_id: 0,
				start_from: 0,
				limit_value: 20,
			},
			{
				onSuccess: async res => {
					setleaveTypeOptions(res)
				},
				onError: err => {
					console.log({ err })
				},
			}
		)
	}, [])

	const onLeaveTypeChange = e => {
		setleaveType(e)
		setleaveForm(ev => ({
			...ev,
			Leave_available: e.details.leaves_unused,
			leave_type_id: e.details.leave_type_id,
			leave_type_name: e.details.leave_type_name,
			leave_type_category_name: e.details.leave_type_category_name,
			leave_type_category_id: e.details.leave_type_category_id,
		}))
	}

	const onDatesChange = event => {
		let { name, value } = event.target
		if (name === 'leave_applied' && value < 0) {
			toast({
				title: 'Enter valid dates',
				status: 'error',
			})
			value = 0
		} else if (name === 'leave_applied' && value > leaveForm.Leave_available) {
			toast({
				title: 'Available leaves are ' + leaveForm.Leave_available,
				status: 'error',
			})
			return
		} else if (
			name === 'leave_applied' &&
			value !== '0.5' &&
			value.split('.').length === 2
		) {
			toast({
				title: 'Plese give valid input 0.5 or (1,2,3..etc)',
				status: 'error',
			})
			return
		}
		setleaveForm(ev => ({
			...ev,
			[name]: value,
		}))
		if (name === 'leave_start_datetime' || name === 'leave_applied') {
			toSetEndDate(
				name === 'leave_start_datetime'
					? value
					: leaveForm.leave_start_datetime,
				name === 'leave_applied' ? value : leaveForm.leave_applied
			)
		}
	}
	const toSetEndDate = (startDate, duration) => {
		setIshalfDay(false)
		let date = ''
		if (duration === 1) {
			date = startDate
		} else if (duration === '0.5') {
			setIshalfDay(true)
		} else {
			date = moment(startDate)
				.add(duration - 1, 'days')
				.format('YYYY-MM-DD')
		}
		setleaveForm(ev => ({
			...ev,
			leave_end_datetime: date,
		}))
	}
	const toFotmateDates = (date, time) => {
		return moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss')
	}

	const onAddForm = () => {
		if (
			!leaveForm.leave_applied ||
			!leaveForm.leave_type_name ||
			!leaveForm.leave_start_datetime ||
			(ishalfDay && (!times.start || !times.end)) ||
			leaveForm.leave_applied === 0
		)
			return
		if (ishalfDay) {
			leaveForm.leave_end_datetime = toFotmateDates(
				leaveForm.leave_start_datetime,
				times.end
			)
			leaveForm.leave_start_datetime = toFotmateDates(
				leaveForm.leave_start_datetime,
				times.start
			)
		} else {
			leaveForm.leave_end_datetime = toFotmateDates(
				leaveForm.leave_end_datetime,
				'23:59'
			)
			leaveForm.leave_start_datetime = toFotmateDates(
				leaveForm.leave_start_datetime,
				'00:00'
			)
		}
		let data = leaveData
		data.push(leaveForm)
		setleaveData(data)
		formSubmission(data)
		onReset()
	}

	const onReset = () => {
		setleaveForm(initialData)
		setleaveType([])
		setIshalfDay(false)
		setTimes({ start: '', end: '' })
	}

	const onDeleteRow = indx => {
		let data = leaveData
		data.splice(indx, 1)
		formSubmission(data)
		setleaveData(data)
		setdummy(!dummy)
	}

	const toReturnTotalLeaves = data => {
		return data.map(a => Number(a.leave_applied)).reduce((a, b) => a + b)
	}

	const formSubmission = formData => {
		if (formData.length > 0) onValidate(true)
		else onValidate(false)
		const {
			asset_id,
			asset_first_name,
			operating_asset_id,
			operating_asset_first_name,
		} = authData
		let finalVal = {
			asset_reference:
				asset_id +
				'|' +
				asset_first_name +
				'|' +
				operating_asset_id +
				'|' +
				operating_asset_first_name,
			leave_data: formData,
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, finalVal),
		})
	}

	useEffect(() => {
		let formData
		if (fields?.[field_id]?.field_value) {
			formData =
				typeof fields?.[field_id]?.field_value === 'string'
					? JSON.parse(fields?.[field_id]?.field_value)
					: fields?.[field_id]?.field_value
		}
		if (formData && formData.leave_data && formData.leave_data.length > 0) {
			setleaveData(formData.leave_data)
		}
	}, [fields[field_id]])

	const onTimesChange = event => {
		const { name, value, min, max } = event.target
		var now = moment(value, 'HH:mm:ss').add(5, 'hours')
		var end = moment('23:59', 'HH:mm:ss')
		var duration = moment.duration(end.diff(now))
		var minutes = duration.asMinutes()
		if (
			moment(value, 'HH:mm:ss').format('HH:mm').split(':')[0] >=
				min.split(':')[0] &&
			minutes >= -1
		) {
			setTimes(ev => ({
				...ev,
				[name]: value,
				end: moment(value, 'HH:mm:ss').add(5, 'hours').format('HH:mm'),
			}))
		} else {
			toast({
				title: 'Please select time from 9 am to 7 pm',
				status: 'error',
			})
		}
	}
	return (
		<Box>
			<Box display='flex' flexWrap='wrap'>
				<Box w='170px' p='7px'>
					<Text mb='5px' color='black'>
						{locale['Leave type']}
					</Text>
					<Select
						className='full-width'
						name='leave_type'
						id={`${field.field_name.split(' ').join('-')}-category`}
						//   isDisabled={(isDisabled || isfromEdit) && !isBulkEdit}
						options={leaveTypeOptions.map(val => {
							return {
								label: val.leave_type_name,
								value: val.leave_type_id,
								details: {
									...val,
								},
							}
						})}
						placeholder={locale['Leave Type']}
						borderRadius='sm'
						size='sm'
						value={[leaveType]}
						//   menuPlacement={fieldIndex > 0 ? 'top' : 'bottom'}
						onChange={e => {
							// setproductCat(e);
							onLeaveTypeChange(e)
						}}
					/>
				</Box>
				<Box w='120px' p='7px'>
					<Text mb='5px' color='black'>
						{locale['Available Leaves']}
					</Text>
					<Input
						placeholder={locale['Available Leaves']}
						isDisabled={true}
						value={leaveForm.Leave_available}
						size='sm'
					/>
				</Box>
				<Box w='100px' p='7px'>
					<Text mb='5px' color='black'>
						{locale['No. of days']}
					</Text>
					<Input
						placeholder={locale['Leaves to be taken']}
						type='number'
						value={leaveForm.leave_applied}
						onChange={onDatesChange}
						step='0.5'
						name='leave_applied'
						size='sm'
						min={0}
						// disabled={true}
					/>
				</Box>
				<Box w='170px' p='7px'>
					<Text mb='5px' color='black'>
						{locale['Start Date']}
					</Text>
					<Input
						placeholder={locale['Start Date Time']}
						type='date'
						size='sm'
						name='leave_start_datetime'
						// min={moment().format('yyyy-MM-DD')}
						value={leaveForm.leave_start_datetime}
						onChange={onDatesChange}
					/>
				</Box>

				<Box w='270px' p='7px'>
					<Text mb='5px' color='black'>
						{ishalfDay ? locale['Time (from - to)'] : locale['End Date']}
					</Text>
					<Flex>
						{!ishalfDay ? (
							<>
								<Input
									placeholder={locale['End Date Time']}
									type='date'
									size='sm'
									name='leave_end_datetime'
									min={moment(leaveForm?.leave_start_datetime).format(
										'yyyy-MM-DD'
									)}
									// min={leaveForm?.leave_start_datetime ? new Date(leaveForm?.leave_start_datetime) : new Date().toISOString()}
									value={leaveForm.leave_end_datetime}
									onChange={onDatesChange}
									isDisabled={true}
								/>
							</>
						) : (
							<Flex>
								<Box>
									<Input
										type='time'
										p='2px'
										height='30px'
										name='start'
										min='09:00'
										max='19:00'
										required
										value={times.start}
										onChange={onTimesChange}
									/>
								</Box>
								<Box>
									<Input
										type='time'
										name='end'
										p=' 2px'
										height='30px'
										isDisabled={true}
										value={times.end}
										onChange={onTimesChange}
									/>
								</Box>
							</Flex>
						)}

						<Button
							color='white'
							bg={localStorage.getItem('color')}
							variant='filled'
							size='sm'
							mx='2'
							px='8'
							isDisabled={isDisabled}
							onClick={() => {
								sectionDetailedTrack({
									category: GA_CATEGORY_LEAVE,
									action: 'Add Leave',
								})
								onAddForm()
							}}
						>
							{locale['Add leave']}
						</Button>
					</Flex>
				</Box>
			</Box>

			{leaveData && leaveData.length > 0 ? (
				<Table overflowY='hidden' overflowX='auto' display='block'>
					<Thead position={'relative'} zIndex={1}>
						<Tr>
							<Th>{locale['Leave type']}</Th>
							<Th>{locale['Available Leaves']}</Th>
							<Th>{locale['Start Date Time']}</Th>
							<Th>{locale['End Date Time']}</Th>
							<Th>{locale['Leaves to be taken']}</Th>
							<Th>{locale['Actions']}</Th>
						</Tr>
					</Thead>
					<Tbody>
						{leaveData.map((ele, index) => {
							return (
								<Tr key={index}>
									<Td>{ele.leave_type_name}</Td>
									<Td>
										{/* <Input
                                            size="sm"
                                            borderRadius="sm"
                                            type="number"
                                            min={0}
                                            value={ele.Leave_available}
                                        // onChange={e => onQntyChange(e, p)}
                                        // disabled={isDisabled}
                                        /> */}
										<Td>{ele.Leave_available}</Td>
									</Td>

									<Td>{ele.leave_start_datetime}</Td>
									<Td>{ele.leave_end_datetime}</Td>
									{false ? (
										<Td>
											<Input
												size='sm'
												borderRadius='sm'
												type='number'
												min={0}
												value={ele.leave_applied}
												//   onChange={e => onPriceChange(e, p)}
												//   disabled={isDisabled}
											/>
										</Td>
									) : (
										<Td>{ele.leave_applied}</Td>
									)}
									<Td>
										<IconButton
											color='white'
											bg={localStorage.getItem('color')}
											variant='filled'
											aria-label='Call Segun'
											size='sm'
											isDisabled={isDisabled}
											onClick={() => {
												sectionDetailedTrack({
													category: GA_CATEGORY_LEAVE,
													action: 'Delete Leave',
												})
												onDeleteRow(index)
											}}
											icon={<MdDelete size='20px' />}
										/>
									</Td>
								</Tr>
							)
						})}
						<Tr>
							<Td></Td>
							<Td></Td>
							<Td>{locale['Total Leaves']}</Td>
							<Td></Td>
							<Td>{toReturnTotalLeaves(leaveData)}</Td>
							<Td></Td>
						</Tr>
					</Tbody>
				</Table>
			) : null}
		</Box>
	)
}

export default Leave
